/**
 * LEVI-HAT Data UI Dashboard (React component)
 *
 * This component displays a dashboard with various features for retrieving 
 * and viewing tutoring session source data and derived data.
 * 
 * The component currently provides the following features:
 *   1. Number of sessions by day
 *   2. Annotated transcript for a given session ID
 *   3. Tutor averages viewer
 *   4. LLM response generator
 *   5. Analytics for talk moves
 *
 */

import React, { useEffect, useState} from "react";
import {  Menu, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import "./styles.css";
import { Line , Pie} from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { Button, TextField, Grid, CircularProgress, Paper, IconButton } from "@mui/material";
import exportFromJSON from "export-from-json";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import TutorChart from './TutorAveragesChart';
import { DataGrid } from '@mui/x-data-grid';
import { Tooltip as MaterialTooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useNavigate } from 'react-router-dom';
import userpool from './userpool';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { clamp, set } from "date-fns";

// pie charts
import { Card, CardContent, Typography } from '@mui/material';
import { ArcElement } from 'chart.js';

ChartJS.register(
  LineElement,
  BarElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin,
  ArcElement, 
  Tooltip,
  Legend,
  ChartDataLabels,
);

// Table to display the annotated transcript
const SegmentsTable = ({ segments }) => {
  return (
    <div style={{ marginTop: "50px", marginBottom: "50px" }}>
      <TableContainer component={Paper} style={{ maxHeight: 800, overflow: 'scroll' }}>
        <Table aria-label="segments table">
          <TableHead>
            <TableRow>
              <TableCell style={{ padding: '5px' }}>Statement ID</TableCell>
              <TableCell style={{ padding: '5px' }}>Speaker ID</TableCell>
              <TableCell style={{ padding: '5px' }}>Speaker type</TableCell>
              <TableCell style={{ padding: '5px' }}>Chat or Speech</TableCell>
              <TableCell style={{ padding: '5px' }}>Start</TableCell>
              <TableCell style={{ padding: '5px' }}>End</TableCell>
              <TableCell style={{ padding: '5px' }}>Text</TableCell>
              <TableCell style={{ padding: '5px' }}>Talk move category</TableCell>
              <TableCell style={{ padding: '5px' }}>Talk move Type</TableCell>
              <TableCell style={{ padding: '5px' }}>Classification probability</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {segments.slice(0, 20).map((segment, index) => (
              <React.Fragment key={index}>
                {segment.map((item, subIndex) => (
                  <TableRow key={subIndex}>
                    <TableCell style={{ padding: '5px' }}>{item.id}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{item.participantId}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{item.speakerType}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{item.type}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{item.start}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{item.end}</TableCell>
                    <TableCell style={{ padding: '5px' }}>{item.text}</TableCell>
                    <TableCell style={{ padding: '5px' }}>
                      {item.annotations && item.annotations.find(annotation => annotation.annotationType === "talk_move") ? 
                        item.annotations.find(annotation => annotation.annotationType === "talk_move").category : null}
                    </TableCell>
                    <TableCell style={{ padding: '5px' }}>
                      {item.annotations && item.annotations.find(annotation => annotation.annotationType === "talk_move") ? 
                        item.annotations.find(annotation => annotation.annotationType === "talk_move").values[0] : null}
                    </TableCell>
                    <TableCell style={{ padding: '5px' }}>
                    {(() => {
                      const talkMoveAnnotation = item.annotations && item.annotations.find(annotation => annotation.annotationType === "talk_move");
                      if (talkMoveAnnotation) {
                        const probability = talkMoveAnnotation.probability;
                        return Array.isArray(probability)
                          ? Math.max(...probability.map(Number)).toFixed(3)
                          : parseFloat(probability).toFixed(3);
                      }
                      return null;
                    })()}
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};


const useStyles = makeStyles({
  rowIconStyle: {
    minWidth: 50,
    minHeight: 50
  },
  tableRowStyle: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "darkGrey"
    }
  },
  rowSelectionCheckboxStyle: {
    // opacity: "calc(var(--oneRowSelected))",
    opacity: 0,
    "$tableRowStyle:hover &": {
      opacity: 1
    }
  }
});

function Dashboard() {

  const navigate = useNavigate();

  // react functional states to manage the checkboxes
  const [checkedRows, setCheckedRows] = useState({});
  const styles = useStyles();
  const DEFAULT_TABLE_ROW_ICON_COLOR = "grey";

  
  
  //states used to manage the data returned from the API
  const [data, setData] = useState([]);
  const [transcriptData, setTranscriptData] = useState([]);
  const [averagesData, setAveragesData] = useState([]);


  //User input states
  const [selectedDate, setSelectedDate] = useState("");
  const [sessionLoading, setSessionLoading] = useState(false);
  const [sessionDownload, setSessionDownload] = useState(false);
  const [averagesLoading, setAveragesLoading] = useState(false);
  const [transcriptLoading, setTranscriptLoading] = useState(false);
  const [transcriptDownload, setTranscriptDownload] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [transcriptSessionID, setTranscriptSessionID] = useState("");
  const [showSession, setShowSession] = useState(false);
  const [showAnnotatedTranscript, setShowAnnotatedTranscript] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [sessionFlag, setSessionFlag] = useState(false);
  const [transcriptFlag, setTranscriptFlag] = useState(false);
  const [showSessionGraph, setShowSessionGraph] = useState(false);
  const [showTranscriptTable, setShowTranscriptTable] = useState(false);
  const [showTutorAverages, setShowTutorAverages] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [tutorList, setTutorList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedMetric, setSelectedMetric] = useState("");
  const [selectedTutorIds, setSelectedTutorIds] = useState([]); 
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [chartMetric, setChartMetric ] = useState({});


  //LLM prompt summary states
  const [showLLM, setShowLLM] = useState(false);
  const [showLLMSummary, setShowLLMSummary] = useState(false);
  const [promptData, setPromptData] = useState('You are an experienced tutor of middle school mathematics. We want to emphasize equitable student engagement. Given the following transcript that identifies tutor and student statements in a recent tutoring session, provide insights into which students were or were not engaged and provide ideas on how the tutor might improve equitable engagement. Provide response in a JSON format of: { \"engagementFeedback\": <feedback> \"engagementAdvice\": <advice> }\n\nTranscript:\n<<transcript>>');
  const [llmSessionID, setLLMSessionID] = useState('');
  const [llmLoading, setLLMLoading] = useState(false);
  const [llmSummary, setLLMSummary] = useState('');
  const [selectedModel, setSelectedModel] = useState('gpt-4o-mini');

  // Analytics states
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [tutorTalkMoves, setTutorTalkMoves] = useState([]);
  const [studentTalkMoves, setStudentTalkMoves] = useState([]);
  const talkMovesLabels = ['Content Knowledge', 'Learning Community', 'Rigorous Thinking'];
  const [analyticsLoading, setAnalyticsLoading] = useState(false);
  const [showGraphs, setShowGraphs] = useState(false);
  const [sessionOverview, setSessionOverview] = useState('');
  const [mathTopicsCovered, setMathTopicsCovered] = useState(''); 
  const [feedbackSessionHighlights, setFeedbackSessionHighlights] = useState('');
  const [studentParticipation, setStudentParticipation] = useState([]);
  const [analyticstSessionID, setAnalyticsSessionID] = useState('');
  const [participationMetric, setParticipationMetric] = useState('sentences'); // default value = sentences

  // input_type for pipeline identifier
  const [inputType, setInputType] = useState('2024-fall-standard');
  const [schoolYear, setSchoolYear] = useState('2024-2025');

  // Mapping of input types to school years
  const inputTypeToSchoolYearMap = {
    '2024-fall-standard': '2024-2025',
    '2023-fall-standard': '2023-2024',
  };

  // error handling states
  const [notFoundError, setNotFoundError] = useState(""); // sessiondata not found
  const [ analyticsNotFoundError, setAnalyticsNotFoundError] = useState(""); // analytics session not found
  const [analyticsFlag, setAnalyticsFlag] = useState(false); // analytics service - session field empty before clicking view

  // Convert the selected date to the desired format
  const dateString = selectedDate;
  const parts = dateString.split("-");
  const rearrangedDate = `${parts[1]}-${parts[2]}-${parts[0]}`;

  const formattedDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const formattedEndDate = formattedDate.split("/").join("-");

  //Column headers for the tutor list table
  const TutorColumns = [
    { field: 'userId', headerName: ' Tutor ID', width: 90 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'siteName', headerName: 'Site Name', width: 150 },
    { field: 'siteId', headerName: 'Site ID', width: 150 },
  ];
 
  // Minimum allowed date for averages viewer
  const minStartDate = '2023-09-01'; 

  // functions to handle user input states
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    
  };

  // const handleAuthTokenChange = (e) => {
  //   setAuthToken(e.target.value);
  // };

  const handleSessionIdChange = (e) => {
    setTranscriptSessionID(e.target.value);
  };

  const handleselectedRegionChange = (e) => {
    setSelectedRegion(e.target.value);
  };

  const handleSelectedMetricChange = (e) => {
    setSelectedMetric(e.target.value);
  };

  const handleCellClick = (params) => {
    const userId = params.row.userId;
    const isSelected = selectedTutorIds.includes(userId);

    if (isSelected) {
      // Remove from selectedTutorIds
      setSelectedTutorIds(prevIds => prevIds.filter(id => id !== userId));
    } else {
      // Add to selectedTutorIds
      setSelectedTutorIds(prevIds => [...prevIds, userId]);
    }
  };


  const handleTutorSelection = (selection) => {
    setSelectedTutorIds(selection.selectionModel);
  }

  const handleNoOfSessionClick = () => {
    setShowSession((prev) => !prev);
    setShowSession(!showSession); 
    setShowSessionGraph((prev) => !prev);
    setShowSessionGraph(!showSessionGraph);
  };

  const handleAnnotatedTranscriptClick = () => {
    setShowAnnotatedTranscript((prev) => !prev); 
    setShowAnnotatedTranscript(!showAnnotatedTranscript); 
    setShowTranscriptTable((prev) => !prev);
    setShowTranscriptTable(!showTranscriptTable);
  };

  const handleLLMClick = () => {
    setShowLLM((prev) => !prev);
    setShowLLM(!showLLM);
    setShowLLMSummary((prev) => !prev);
    setShowLLMSummary(!showLLMSummary);
  };

  const handleAnalyticsClick= () => {
    setShowAnalytics((prev) => !prev);
    setShowAnalytics(!showAnalytics);
    setShowGraphs((prev) => !prev);
    setShowGraphs(!showGraphs);
  };

  const handleTutorAveragesViewer = () => {
    setShowTutorAverages((prev) => !prev); 
    setShowTutorAverages(!showTutorAverages); 
    
  };

  // Function that handles the retrieval of the number of sessions by day
  const handleSessionSendClick = async () => {

    if(selectedDate === ""){
      setSessionFlag(true);
      return;
    }

    setSessionLoading(true);
    try {
      await isTokenExpired();
      let authToken = sessionStorage.getItem("access_token");
      // Convert the selected date to epoch format
      const epochDate = new Date(selectedDate).getTime();

      // Make the API request with the epoch date
      const response = await axios.get(
        `https://api.hatproject.org/v1/analysis/sessiondata?summaryByDay&dayTime=${epochDate}`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      setAuthError(false);
      setSessionFlag(false);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setAuthError(true);
    } finally {
      setSessionLoading(false);
    }
  };

  // Function which handles the download to CSV of the number of sessions by day
  const handleSessionDownloadClick = async() => {
    if(selectedDate === ""){
      setSessionFlag(true);
      return;
    }
    setSessionDownload(true);
    if( data.length === 0 || data[0]._id !== selectedDate) {
      let newSessionData = [];
      try {
        await isTokenExpired();
        let authToken = sessionStorage.getItem("access_token");
        // Convert the selected date to epoch format
        const epochDate = new Date(selectedDate).getTime();
        
        // Make the API request with the epoch date
        const reResponse = await axios.get(
          `https://api.hatproject.org/v1/analysis/sessiondata?summaryByDay&dayTime=${epochDate}`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );
        setAuthError(false);
        setSessionFlag(false);
        newSessionData = reResponse.data;
        setData(reResponse.data);

        const fileName = `SessionsByDay_${rearrangedDate}_to_${formattedEndDate}`;
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data: newSessionData, fileName: fileName, exportType: exportType});
      } catch (error) {
        console.error("Error fetching data:", error);
        setAuthError(true);
      } 
    }
    else {
    const fileName = `SessionsByDay_${rearrangedDate}_to_${formattedEndDate}`;
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data: data, fileName: fileName, exportType: exportType});
    }
    setSessionDownload(false);
  };

  // Function that handles the retrieval of the annotated transcript for the user provided session id and school year
  const handleTranscriptSendClick = async () => {
    if (transcriptSessionID === "") {
      setTranscriptFlag(true);
      setNotFoundError("");
      return;
    }
    setTranscriptLoading(true);
    try {
      await isTokenExpired();
      let authToken = sessionStorage.getItem("access_token");
      // Make the API request
      const response = await axios.get(
        `https://api.hatproject.org/v1/analysis/sessiondata?transcript=${transcriptSessionID}&input_type=${inputType}`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      setAuthError(false);
      setTranscriptFlag(false);
      setNotFoundError("");
      setTranscriptData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // 404 error - session not found
        setTranscriptData([]);
        const errorMessage = `Session ${transcriptSessionID} not found for school year ${schoolYear}`
        console.warn(errorMessage, error);
        setNotFoundError(errorMessage);
      } else {
        const errorMessage = `The data could not be retrieved. The error code ${error.response.status} was received`;
        console.warn(errorMessage, error);
        setNotFoundError(errorMessage);
        setAuthError(true); 
      }
    } finally {
      setTranscriptLoading(false);
    }
  };
  

  const handleTranscriptDownloadClick = async () => {
    // Check if a session ID is provided
    if (transcriptSessionID === "") {
      setTranscriptFlag(true); 
      setNotFoundError("");
      return;
    }
  
    // Indicate that the transcript download is in progress
    setTranscriptDownload(true);
    setTranscriptFlag(false);
    setNotFoundError(""); 
  
    // Check if transcriptData has valid data for the selected session ID
    if (transcriptData.length > 0 && transcriptData[0].sessionId === transcriptSessionID && transcriptData[0].input_type === inputType) {
      const segments = transcriptData.map((record) => record.segments);
      const flattenedSegments = segments.reduce((acc, val) => acc.concat(val), []);
  
      const segmentsData = flattenedSegments.map(segment => {
        const annotations = segment.annotations || [];
        const talkMoveAnnotation = annotations.find(annotation => annotation.annotationType === "talk_move");
        const talkMoveCategory = talkMoveAnnotation ? talkMoveAnnotation.category : '';
        const talkMoveType = talkMoveAnnotation ? talkMoveAnnotation.values[0] : '';
        const classificationProbability = talkMoveAnnotation 
          ? Array.isArray(talkMoveAnnotation.probability)
            ? Math.max(...talkMoveAnnotation.probability.map(Number)).toFixed(3)
            : talkMoveAnnotation.probability // For single string case
          : '';
  
        return {
          statementId: segment.id,
          speakerId: segment.participantId,
          speakerType: segment.speakerType,
          chatOrSpeech: segment.type,
          start: segment.start,
          end: segment.end,
          text: segment.text,
          talkMoveCategory,
          talkMoveType,
          classificationProbability,
        };
      });
  
      // Download the data as CSV
      const fileName = `AnnotatedTranscript_${transcriptSessionID}`;
      const exportType = exportFromJSON.types.csv;
      exportFromJSON({ data: segmentsData, fileName: fileName, exportType: exportType });
  
    } else {
      // If no data or session ID is different, make an API call to fetch new data
      let newTranscriptData = [];
      try {
        await isTokenExpired();
        const authToken = sessionStorage.getItem("access_token");
  
        // API call to fetch the transcript data
        const reResponse = await axios.get(
          `https://api.hatproject.org/v1/analysis/sessiondata?transcript=${transcriptSessionID}&input_type=${inputType}`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );
  
        // Store the fetched data
        setAuthError(false);
        setTranscriptFlag(false);
        newTranscriptData = reResponse.data;
        setTranscriptData(newTranscriptData);
  
        // Flatten and process the segments data
        const segments = newTranscriptData.map((record) => record.segments);
        const flattenedSegments = segments.reduce((acc, val) => acc.concat(val), []);
  
        const newSegmentsData = flattenedSegments.map(segment => {
          const annotations = segment.annotations || [];
          const talkMoveAnnotation = annotations.find(annotation => annotation.annotationType === "talk_move");
          const talkMoveCategory = talkMoveAnnotation ? talkMoveAnnotation.category : '';
          const talkMoveType = talkMoveAnnotation ? talkMoveAnnotation.values[0] : '';
          const classificationProbability = talkMoveAnnotation 
          ? Array.isArray(talkMoveAnnotation.probability)
            ? Math.max(...talkMoveAnnotation.probability.map(Number)).toFixed(3)
            : talkMoveAnnotation.probability // For single string case
          : '';
  
          return {
            statementId: segment.id,
            speakerId: segment.participantId,
            speakerType: segment.speakerType,
            chatOrSpeech: segment.type,
            start: segment.start,
            end: segment.end,
            text: segment.text,
            talkMoveCategory,
            talkMoveType,
            classificationProbability,
          };
        });
  
        // Download the new data as CSV
        const fileName = `AnnotatedTranscript_${transcriptSessionID}`;
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data: newSegmentsData, fileName: fileName, exportType: exportType });
  
      } catch (error) {
        // Handle errors appropriately
        if (error.response && error.response.status === 404) {
          setTranscriptData([]); // Clear the data
          const errorMessage = `Session ${transcriptSessionID} not found for school year ${schoolYear}`;
          console.warn(errorMessage, error);
          setNotFoundError(errorMessage); // Display 404 error message in the UI
        } else {
          const errorMessage = `The data could not be retrieved. Error code: ${error.response.status}`;
          console.warn(errorMessage, error);
          setNotFoundError(errorMessage); // Display generic error message
          setAuthError(true); // Indicate an authorization error
        }
      }
    }
  
    // Indicate that the download process is complete
    setTranscriptDownload(false);
  };
  

  // Function that handles metric viewing of averages for the selected tutors from the displayed list
  const handleAveragesViewClick = async (selectedTutorIds,startDate,endDate,selectedMetric) => {
    setAveragesData([]);
    setAveragesLoading(true);
    let newAveragesData = [];
    selectedTutorIds.sort();
    for ( const tutorId of selectedTutorIds) {
      try {
        await isTokenExpired();
        let authToken = sessionStorage.getItem("access_token");
        const response = await axios.get(`https://api.hatproject.org/v1/analysis/averages?tutorId=${tutorId}&fromDate=${startDate}&toDate=${endDate}&metric=${selectedMetric}&aggregate=false&input_type=${inputType}`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );
        newAveragesData = [];
        // check if response.data is falsy or is an empty array
        if (!response.data || response.data.length === 0) {
          const temp = {
            tutorId: tutorId,
            sessions: [],
            label: [],
            averages: [],
          };
          newAveragesData.push(temp);
        }
        // Format the data to the desired structure
        else{
          const temp = response.data.reduce((acc, item) => {
            if (!acc.tutorId) {
              acc.tutorId = item.tutorId;
              acc.sessions = [];
              acc.label = [];
              acc.averages = [];
            }
            acc.label.push(item.weekBeginning);
            acc.averages.push(item.data[0].average);
            acc.sessions.push(item.sessions);
            return acc;
          }, {});
          newAveragesData.push(temp);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
    }
    setAveragesData(prevData => [...prevData, ...newAveragesData]);
    setChartMetric(selectedMetric);
    setAveragesLoading(false);
  }
};

// Function that handles the LLM prompt summary
const handleLLMSummaryClick = async () => {
  setLLMLoading(true);
  try {
    await isTokenExpired();
    let authToken = sessionStorage.getItem("access_token");
    const data = {
      prompt: promptData,
      sessionId: llmSessionID,
      model: selectedModel,
      input_type: inputType,
    }
    const response = await axios.post(
      `https://api.hatproject.org/v1/llmResponse`, data,
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    const formattedResponse = JSON.stringify(response.data, null, 2);
    setLLMSummary(formattedResponse);
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLLMLoading(false);
  }
};

// Function that handles analytics of the session
const handleTalkmoves = async () => {
  if (transcriptSessionID === "") {
    setAnalyticsFlag(true);
    setAnalyticsNotFoundError("");
    return;
  }
  setAnalyticsLoading(true);
  try {
    await isTokenExpired();
    let authToken = sessionStorage.getItem("access_token");
    // Make the API request
    const response = await axios.get(
      `https://api.hatproject.org/v1/analysis/sessiondata?sessionId=${transcriptSessionID}&input_type=${inputType}`,
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    setAuthError(false);
    setAnalyticsFlag(false);
    setAnalyticsNotFoundError("");
    setAnalyticsSessionID(response.data[0].sessionId);

    const extractCountsInOrder = (data, desiredOrder) => {
      return desiredOrder.map(label => {
          const category = data.find(item => item.label === label);
          return category ? category.count : null;
      });
  };
    setTutorTalkMoves(extractCountsInOrder(response.data[0].metrics.tutorTalkmoveCategories, talkMovesLabels));
    setStudentTalkMoves(extractCountsInOrder(response.data[0].metrics.studentTalkmoveCategories, talkMovesLabels));
    setSessionOverview(response.data[0].infoBoxes[0].boxContent[10].infoText.join(""));
    setMathTopicsCovered(response.data[0].infoBoxes[0].boxContent[9].infoText.join(""));
    setFeedbackSessionHighlights(response.data[0].infoBoxes[0].boxContent[8].infoText.join(""));
    const studentData = data.filter((entry) => entry.participantType === "student");
    setStudentParticipation(response.data[0].participantContributions.filter((entry) => entry.participantType === "student"));
  } 
  catch (error) {
    if (error.response && error.response.status === 404) {
      // 404 error - session not found
      const errorMessage = `Session ${transcriptSessionID} not found for school year ${schoolYear}`
      console.warn(errorMessage, error);
      setAnalyticsNotFoundError(errorMessage);
    } else {
      const errorMessage = `The data could not be retrieved. The error code ${error.response.status} was received`;
      console.warn(errorMessage, error);
      setAnalyticsNotFoundError(errorMessage);
      setAuthError(true); 
    }
  } finally {
    setAnalyticsLoading(false);
  }
};




  // Formatting the data for the sessions per day graph
  const NoSessions = data.map((record) => record.count);
  const sessionID = data.map((record) => record._id);

  // Line graph data
  const data_session = {
    labels: sessionID,
    datasets: [
      {
        label: "Number of sessions",
        data: NoSessions,
        backgroundColor: "rgba(255,0,0,0.2)",
        borderColor: "rgba(75,0,0,1)",
      },
    ],
  };

  const options_session = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "Number of sessions",
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "Number of Sessions",
        },
      },
      x: {},
    },
    
  };

  // Function to retrieve the list of regions available
  const fetchRegion = async () => {
    try {
      await isTokenExpired();
      let authToken = sessionStorage.getItem("access_token");
      const response = await axios.get('https://api.hatproject.org/v1/analysis/sessiondata?userRegions',
      {
        headers: {
          Authorization: authToken,
        },
      }
      ); 
      setRegionList(response.data);
    } catch (error) {
      console.error('Error fetching session options:', error);
    }
  };

  // Function to retrieve all active tutors from the selected region
  const fetchTutors = async (region) => {
    try {
      await isTokenExpired();
      let authToken = sessionStorage.getItem("access_token");
      const response = await axios.get(`https://api.hatproject.org/v1/analysis/sessiondata?userRole=tutor&region=${region}`, 
      {
        headers: {
          Authorization: authToken,
        },
      }
      ); 
      setTutorList(response.data);
      // Discards other tutors if the region is changed
      setSelectedTutorIds([]);
    } catch (error) {
      console.error('Error fetching session options:', error);
    }
  };

  // Runs on page load to fetch the region list
  useEffect(() => {
    fetchRegion();
  }, []);

  //Runs when inputType changes to map school year accordingly
  useEffect(() => {
    const correspondingSchoolYear = inputTypeToSchoolYearMap[inputType];
    if (correspondingSchoolYear) {
      setSchoolYear(correspondingSchoolYear);
    }
  }, [inputType]);

  // Function to handle the logout
  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/');
  };

  // Function to check if token is expired and refresh auth token if necessary
  const isTokenExpired = async() => {
    let expiryTime = sessionStorage.getItem('token_expiry');
    expiryTime = parseInt(expiryTime, 10);
    if (Date.now() < expiryTime - 60000)
    {
      return true;
    }
    else {
      try {
        const response = await axios.post(
          'https://hat-server.auth.us-east-1.amazoncognito.com/oauth2/token',
          new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: userpool.clientId,
            refresh_token: sessionStorage.getItem('refresh_token'),
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            }
          }
        );
        sessionStorage.setItem("access_token", response.data.id_token);
        let newExpiryTime = expiryTime + 3600 * 1000;
        sessionStorage.setItem("token_expiry", newExpiryTime);
        return true;
      } catch (error) {
        console.error('Error refreshing token:', error);
      }

    }
  };

  const TextCard = ({ title, text }) => {
    return (
      <Card sx={{ width: 350, height: 500, margin: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" align="center" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" align="left" sx={{ marginTop: 4 }}>
            <div
              dangerouslySetInnerHTML={{ __html: text }} // Render sanitized HTML
            />
          </Typography>
        </CardContent>
      </Card>
    );
  };


  
  const TileWithPieChart = ({ title, values}) => {
    const data_pie = {
      labels: talkMovesLabels,
      datasets: [
        {
          data: values,
          backgroundColor: ["#40E0D0", "#FFA500", "#FF6347"],
          hoverBackgroundColor: ["#36D7B7", "#FFB347", "#FF7F7F"]
        }
      ]
    }
    const options_pie = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        datalabels: {
          color: '#fff',              // Label color
          font: {
            weight: 'bold',
            size: 12
          },
          formatter: (value, context) => {
            const label = context.chart.data.labels[context.dataIndex];  // Static label
            const total = values.reduce((sum, val) => sum + val, 0); // Calculate the total
            return `${label}\n${value} / ${total} `; // Display in format "value / total (percentage%)"
          },
          align: 'center',             // Position of label inside the segment
          anchor: 'center',              // Position of label outside the segment
          clamp: true,
          // clip: true,
        }
      }
    };

    return (
      <Card sx={{ width: 400, height: 500, margin: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" align="center" gutterBottom>
            {title}
          </Typography>
          <Pie data={data_pie} options={options_pie} />
        </CardContent>
      </Card>
    );
  };
  

  const StudentTalkParticipation = ({ title }) => {
    const data_pie = {
      labels: studentParticipation.map((entry) => entry.participantId),
      datasets: [
        {
          data: studentParticipation.map((entry) => entry[participationMetric]),
          backgroundColor: ["#40E0D0", "#FFA500", "#FF6347"],
          hoverBackgroundColor: ["#36D7B7", "#FFB347", "#FF7F7F"]
        }
      ]
    }
    const options_pie = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        datalabels: {
          color: '#fff',           
          font: {
            weight: 'bold',
            size: 12
          },
          formatter: (value, context) => {
            const label = context.chart.data.labels[context.dataIndex]; 
            const total = studentParticipation.map((entry) => entry[participationMetric]).reduce((sum, val) => sum + val, 0);
            return `${label}\n${value} / ${total} `; 
          },
          align: 'center',            
          anchor: 'center',            
          clamp: true,
          // clip: true,
        }
      }
    };

    return (
      <Card sx={{ width: 400, height: 600, margin: 2, boxShadow: 3, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="metric-select-label">Metric</InputLabel>
          <Select
            labelId="metric-select-label"
            id="metric-select"
            value={participationMetric}
            onChange={(e) => setParticipationMetric(e.target.value)}
            label="Student Activity Metric"
          >
            <MenuItem value="sentences">Sentences</MenuItem>
            <MenuItem value="talkMoves">Talk Moves</MenuItem>
            <MenuItem value="time">Talk Time in seconds</MenuItem>
            <MenuItem value="questions">Questions</MenuItem>              
          </Select>
        </FormControl>
      </Box>


      <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Pie data={data_pie} options={options_pie} />
      </CardContent>
    </Card>
    );
  };


  return (
    <div style={{ position: "relative", margin: "auto", width: "80vw" }}>
      
      <div style={{ position: "relative", margin: "auto", width: "80vw", textAlign: "center" }}>
          <h1>LEVI-HAT Data Viewer</h1>
      </div>
      <div className="dashboard">
        <Button className="dashboard"
          variant="contained"
          color="primary"
          onClick={handleLogout}
        >
          Logout
        </Button>
        </div>

        <Grid container spacing={2} alignItems="center" style={{paddingBottom:'30px', paddingTop:'10px'}}>
        <Grid item>
        <FormControl style={{width: '200px'}}>
        <InputLabel id="region-label">Select School Year</InputLabel>
        <Select
          labelId="input-label"
          id="selectedinputtype"
          value={inputType}
          onChange={(e) => setInputType(e.target.value)}
          label="Select School Year"
        >
          <MenuItem value={'2023-fall-standard'}>2023-2024</MenuItem>
          <MenuItem value={'2024-fall-standard'}>2024-2025</MenuItem>          
        </Select>
      </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item>
        <h3>
        <a href= "#" onClick={handleAnnotatedTranscriptClick} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>Annotated transcript </a>
        <a onClick={handleAnnotatedTranscriptClick} style = {{cursor: 'pointer'}}>{showAnnotatedTranscript ? <span>&#x25BC;</span> : <span>&#x25B6;</span>} </a>
        </h3>
        </Grid>
      </Grid>
      
      
      {showAnnotatedTranscript && (
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <TextField style={{ width: "400px" }}
            id="transcriptSessionID"
            label="Enter the session ID"
            type="text"
            value={transcriptSessionID}
            onChange={handleSessionIdChange}
            
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleTranscriptSendClick}
            disabled={transcriptLoading}
          >
            {transcriptLoading ? <CircularProgress size={24} /> : "View"}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleTranscriptDownloadClick}
            disabled={transcriptDownload}
          >
            {transcriptDownload ? <CircularProgress size={24} /> : "Download"}
          </Button>
        </Grid>
      </Grid>
      )}

      {transcriptFlag && (
        <Grid container spacing={2} alignItems="center" style ={{padding: 10}}>
        <p style={{ color: 'red' }}>
          Please enter a session ID first.
        </p>
        </Grid>
      )}

      {notFoundError && (
        <Grid container spacing={2} alignItems="center" style ={{padding: 10}}>
        <p style={{ color: 'red' }}>
          {notFoundError}
        </p>
        </Grid>
      )}
      

      { showTranscriptTable && transcriptData.length > 0  && (
        <>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Session ID: {transcriptData[0].sessionId}
        </Typography><SegmentsTable segments={transcriptData.map((record) => record.segments)} /></>
      )}

      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <h3> 
          <a href="#" onClick={handleAnalyticsClick} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>Analytics </a> 
          <a onClick={handleAnalyticsClick} style = {{cursor: 'pointer'}}>{showAnalytics ? <span>&#x25BC;</span> : <span>&#x25B6;</span>}</a>
          </h3>
        </Grid>
      </Grid>

      {showAnalytics && (
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <TextField style={{ width: "400px" }}
            id="transcriptSessionID"
            label="Enter the session ID"
            type="text"
            value={transcriptSessionID}
            onChange={handleSessionIdChange}
            
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleTalkmoves}
            disabled={analyticsLoading}
          >
            {analyticsLoading ? <CircularProgress size={24} /> : "View"}
          </Button>
        </Grid>
      </Grid>
      )}

      {analyticsFlag && (
        <Grid container spacing={2} alignItems="center" style ={{padding: 10}}>
        <p style={{ color: 'red' }}>
          Please enter a session ID first.
        </p>
        </Grid>
      )}

      {analyticsNotFoundError && (
        <Grid container spacing={2} alignItems="center" style ={{padding: 10}}>
        <p style={{ color: 'red' }}>
          {analyticsNotFoundError}
        </p>
        </Grid>
      )}

      { showGraphs && tutorTalkMoves.length > 0  && (
        <div style={{ padding: 20 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Talk Moves Distribution
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            Session ID: {analyticstSessionID}
          </Typography>
          <Grid container justifyContent="center" spacing={2}>
            <TileWithPieChart title=" Tutor Talk Move Distribution" values={tutorTalkMoves}/>
            <TileWithPieChart title=" Student Talk Move Distribution" values={studentTalkMoves} />
            <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <TextCard title="Math Topic Covered" text={mathTopicsCovered} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextCard title="Session Overview" text={sessionOverview} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextCard
                title="Feedback Session Highlights"
                text={feedbackSessionHighlights}
              />
            </Grid>
          </Grid>
            <StudentTalkParticipation title="Activity per Student" />
          </Grid>
        </div>
      )}

      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <h3> 
          <a href="#" onClick={handleLLMClick} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>LLM Session Analysis</a> 
          <a onClick={handleLLMClick} style = {{cursor: 'pointer'}}>{showLLM ? <span>&#x25BC;</span> : <span>&#x25B6;</span>}</a>
          </h3>
        </Grid>
      </Grid>

      {showLLM && (
       
      <Grid container spacing={2} alignItems="flex-start" direction="column">
      <Grid item>
        <TextField style={{ width: "400px" }}
          id="llmSessionID"
          label="Enter the session ID"
          type="text"
          value={llmSessionID}
          onChange={(e) => setLLMSessionID(e.target.value)}
        />
      </Grid>
      
      <Grid item container spacing={1} alignItems="center">
          <Grid item>
            <TextField
              style={{ width: '800px' }}
              id="prompt"
              label="Enter the prompt"
              type="text"
              multiline
              rows={15}
              value={promptData}
              onChange={(e) => setPromptData(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <MaterialTooltip title={
              <React.Fragment>
                <span style={{ fontSize: '1.5em', lineHeight: '1.3' }}>
              <p>Use this feature to send a request to a ChatGPT model. You may use the supplied prompt or replace it with your own.</p><p>If you include the pattern: 
              &nbsp;<strong>&lt;&lt;transcript&gt;&gt;</strong>
              <br />
              then the session transcript with speaker attributions (but not timestamps) will be added to the prompt.</p>
              <p>The transcript will have the form:
              <blockquote>
                <em>tutor [tutorName]: Is the coefficient one?<br />
              student [studentName]: It's one.</em>
              </blockquote>
              with the tutor and student names used instead of "tutorName" and "studentName".
              </p>
              <p>
              Other patterns that you can use are:
              <ul>
              <li><strong>&lt;&lt;studentList&gt;&gt;</strong><br/> replaced with a comma-separated list of student names.</li>
              <li><strong>&lt;&lt;studentCount&gt;&gt;</strong><br/> replaced with the count of "participantContributions.participantType" == "student" objects.</li>
              <li><strong>&lt;&lt;sessionTitle&gt;&gt;</strong><br/> replaced with either "sessionMetadata.content.title" if it exists and "sessionMetadata.title" if "content" doesn't exist.</li>
              </ul>
              Note: Information sent in prompts using a ChatGPT API call are not kept or used for training additional models.
              </p>
              </span>
            </React.Fragment>
            }>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </MaterialTooltip>
          </Grid>
        </Grid>



      <Grid item>
      <FormControl style={{width: '200px'}}>
        <InputLabel id="region-label">Select Model</InputLabel>
        <Select
          labelId="model-label"
          id="selectedmodel"
          value={selectedModel}
          onChange={(e) => setSelectedModel(e.target.value)}
          label="Select Model"
        >
          <MenuItem value={'gpt-3.5-turbo'}>gpt-3.5-turbo</MenuItem>
          <MenuItem value={'gpt-4o'}>gpt-4o</MenuItem>  
          <MenuItem value={'gpt-4o-mini'}>gpt-4o-mini</MenuItem>  
          <MenuItem value={'Titan Express V1'}>Titan Express V1</MenuItem>        
          <MenuItem value={'Titan Text G1 Premier V1'}>Titan Text G1 Premier V1</MenuItem>
          <MenuItem value={'Llama3 8B Instruct V1'}>Llama3 8B Instruct V1</MenuItem>    
          <MenuItem value={'Llama 3 70B Instruct'}>Llama 3 70B Instruct</MenuItem>           
          <MenuItem value={'Mistral 7B Instruct V2'}>Mistral 7B Instruct V2</MenuItem>              
          <MenuItem value={'Mixtral 8X7B Instruct'}>Mixtral 8X7B Instruct</MenuItem>             
        </Select>
      </FormControl>
      </Grid>
      
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLLMSummaryClick}
          disabled={llmLoading}
        >
          {llmLoading ? <CircularProgress size={24} /> : "View"}
        </Button>
      </Grid>
     </Grid>
      )}

      {showLLMSummary && llmSummary && (

      <Grid container spacing={2} alignItems="flex-start" direction="column" style={{paddingTop:'10px', paddingBottom:'50px'}}>
        <Grid item>
        <TextField
          style={{ width: '800px' }}
          id="summary"
          label="Summary"
          type="text"
          value={llmSummary}
          multiline
          rows={15}
          InputProps={{
            readOnly: true,
          }}
        />
        </Grid>
      </Grid>
      )}

      {/* Tutor averages viwer metric dropdown menu */}
      <Grid container spacing={2} alignItems="center">
        <Grid item>
        <h3>
        <a href= "#" onClick={handleTutorAveragesViewer} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>Tutor Averages Viewer </a>
        <a onClick={handleTutorAveragesViewer} style = {{cursor: 'pointer'}}>{showTutorAverages ? <span>&#x25BC;</span> : <span>&#x25B6;</span>} </a>
        </h3>
        </Grid>
      </Grid>

      {showTutorAverages && (
        <Grid container spacing={2} alignItems="center">
        <Grid item>
        <FormControl style={{width: '200px'}}>
              <InputLabel id="region-label">Select Region</InputLabel>
              <Select
                labelId="region-label"
                id="selectedRegion"
                value={selectedRegion}
                onChange={handleselectedRegionChange}
                label="Select Region"
              >
              {regionList.map((option,index) => (
                  <MenuItem key={index} value={option} onClick={ () => fetchTutors(option)}>
                    {option} 
                  </MenuItem>
              ))}            
              </Select>
            </FormControl>
        </Grid>
        </Grid>
      )}

      {selectedRegion && showTutorAverages && (
        <Grid container spacing={2} alignItems="flex-start" direction="column">
        <Grid item>
        <h4>Select one or more tutors:</h4>
        </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: 750, width: '100%', paddingBottom: '20px'}}>
            <DataGrid
              rows={tutorList}
              columns={TutorColumns}
              getRowId={(row) => row.userId}
              pageSize={10}
              rowsPerPageOptions={[10]}
              pagination
              checkboxSelection
              onSelectionModelChange={handleTutorSelection}
              selectionModel={selectedTutorIds}
              onCellClick={handleCellClick}
            />
              </Box>
          </Grid>
          
          <Grid item>
          <h4>Select a date range:</h4>
          </Grid>
          <Grid container spacing={2} alignItems="center">
          <Grid item>
                  <TextField
                    id="start-date"
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
          </Grid>
          <Grid item>
                  <TextField
                    id="end-date"
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
          </Grid>
          </Grid>

          
          <Grid item>
          <h4>Select a metric:</h4>
          </Grid>
          <Grid container spacing={2} alignItems="center" style={{paddingBottom:'10px'}}>
          <Grid item>
            <FormControl style={{width: '200px'}}>
              <InputLabel id="region-label">Metric</InputLabel>
              <Select
                labelId="region-label"
                id="selectedmetric"
                value={selectedMetric}
                onChange={handleSelectedMetricChange}
                label="Metric"
              >
                  <MenuItem value={'longWaitTimes'}>Long Wait Times</MenuItem>
                  <MenuItem value={'shortWaitTimes'}>Short Wait Times</MenuItem>                  
                  <MenuItem value={'multiWordStudentUtterances'}>Multi-word Student Utterances</MenuItem>
                  <MenuItem value={'singleWordStudentUtterances'}>Single-word Student Utterances</MenuItem>
                  <MenuItem value={'sessionTime'}>Session Time</MenuItem>
                  <MenuItem value={'studentMathTermCount'}>Student Math Term Count</MenuItem>
                  {/* <MenuItem value={'studentTalkTime'}>Student Talk Time</MenuItem> */}
                  <MenuItem value={'studentUtteranceCount'}>Student Utterance Count</MenuItem>
                  <MenuItem value={'totalStudentUtteranceWordCount'}>Total Student Utterance Word Count</MenuItem>
                  <MenuItem value={'tutorMathTermCount'}>Tutor Math Term Count</MenuItem> 
                  <MenuItem value={'tutorTalkTime'}>Tutor Talk Time</MenuItem>
                  <MenuItem value={'tutorUtteranceCount'}>Tutor Utterance Count</MenuItem>

              </Select>
            </FormControl>
        </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="center" >
        
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={e => {handleAveragesViewClick(selectedTutorIds,startDate,endDate,selectedMetric)}}
            disabled={averagesLoading}
          >
            {averagesLoading ? <CircularProgress size={24} /> : "View"}
          </Button>
        </Grid>
      </Grid>
        <Grid item>
        {averagesData.map((item, index) => (
        <div key={item.tutorId} className="chart-container" style={{ marginBottom: '20px' }}>
          <TutorChart tutorId={item.tutorId} label={item.label} averages={item.averages} metric={chartMetric} sessions={item.sessions}/>
        </div>
      ))}
      </Grid>
      </Grid>
      )}

      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <h3> 
          <a href="#" onClick={handleNoOfSessionClick} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>Sessions Processed </a> 
          <a onClick={handleNoOfSessionClick} style = {{cursor: 'pointer'}}>{showSession ? <span>&#x25BC;</span> : <span>&#x25B6;</span>}</a>
          </h3>
        </Grid>
      </Grid>

      {sessionFlag && (
      <p style={{ color: 'red' }}>
        Please select a date.
      </p>
      )}

      {showSession && (
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <TextField
            id="datePicker"
            label="Select Date"
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSessionSendClick}
            disabled={sessionLoading}
          >
            {sessionLoading ? <CircularProgress size={24} /> : "View"}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSessionDownloadClick}
            disabled={sessionDownload}
          >
            {sessionDownload ? <CircularProgress size={24} /> : "Download"}
          </Button>
        </Grid>
      </Grid>
    )}

      {showSessionGraph  &&  data.length > 0 && (  
      <div style={{  height: "500px", width: "500px"}}> 
          <Line data={data_session} options={options_session} />
      </div>
      )}
    </div>
  );
}



export default Dashboard;
