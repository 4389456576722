import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Dashboard from './Dashboard';

import CacheBuster from 'react-cache-buster';
import packageFile from '../package.json';
import CacheLoading from './CacheLoading';

import SignUpForm from './Signup-new';
import Login from './Login';


function App() {
  const isProduction = process.env.NODE_ENV === 'production';
  const {version} = packageFile;

 
return (
  <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<CacheLoading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/signup" exact element={<SignUpForm />} />
          <Route path="/Dashboard" element={ <Dashboard/>} />
          <Route path="/" element={<Login/>} />
        </Routes>
      </BrowserRouter>
    </div>
  </CacheBuster>
  );
}

export default App;